import React from 'react';
import useAppContext from 'lib/hooks/useAppContext';
import BrandedLandingPageLayout from 'routes/branded_landing_page/BrandedLandingPageLayout';
import ATKHomePage from 'routes/atk_homepage/ATKHomePage';
import parseUser from 'lib/utils/parseUser';
import utils from '../lib/utils';
import { fetchStrapi } from 'lib/api/strapi';

const AtkHomepageLoggedOut = () => {
  const { url } = useAppContext();
  const newurl = new URL(url);
  newurl.pathname = '';
  return (
    <BrandedLandingPageLayout
      canonical={newurl.href}
      meta={{
        title:
          "America's Test Kitchen: Trusted Recipes and Insights for Home Cooks",
        description:
          "Get the best recipes, equipment reviews, product insights, and techniques from the home cooking and equipment experts at America's Test Kitchen.",
      }}
    >
      <ATKHomePage />
    </BrandedLandingPageLayout>
  );
};

AtkHomepageLoggedOut.getInitialProps = async (context: any) => {
  const { req, res, query } = context;
  const { reducedSegment } = parseUser(req);
  const url = utils.requestToUrl(req);
  const subdomain = url.match(/:\/\/([a-z-23]*)/)?.[1] ?? 'www';

  if (res && reducedSegment === 'multisite') {
    const redirect = await getRedirectPath({ query });

    const urlRedirection =
      subdomain === 'localhost'
        ? `${redirect}`
        : `https://${subdomain}.americastestkitchen.com${redirect}`;

    // Server-side
    res.writeHead(302, {
      Location: urlRedirection,
    });
    res.end();
    return {};
  }

  return {};
};

export default AtkHomepageLoggedOut;

async function getRedirectPath({ query }: { query: any }) {
  const queryString = Boolean(Object.keys(query).length)
    ? `?${new URLSearchParams(query).toString()}`
    : '';

  const strapiHeaderData = await fetchStrapi<any>('/api/headers/current', {
    populate: ['holidayLink'],
  });

  const href = strapiHeaderData?.data?.attributes?.holidayLink?.href;

  const shouldRedirectMembers =
    strapiHeaderData?.data?.attributes?.holidayLink?.redirectHomepageMembers;

  if (!href || !shouldRedirectMembers) {
    return `/browse${queryString}`;
  }

  // just in case query params are added to header
  const pathname = href.split('?')[0];

  return `${pathname}${queryString}`;
}
